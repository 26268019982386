module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"cf0760aaad55f6b2dc81df37c76173ba","debug":true,"track_pageview":true,"persistence":"localStorage"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bio-Futures for Transplanetary Habitats","short_name":"Bio-Futures","start_url":"/","background_color":"#070707","theme_color":"#070707","display":"minimal-ui","icon":"src/images/favicons/favicon.svg","icons":[{"src":"src/images/favicons/maskable-favicon.png","sizes":"512x512","type":"image/png","purpose":"maskable"},{"src":"src/images/favicons/maskable-favicon.png","sizes":"192x192","type":"image/png"},{"src":"src/images/favicons/maskable-favicon.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"178ddd5f9e9e1976c31276e6570f21a2"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
